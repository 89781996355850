import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Image Data
const data = [
  {
    image:'https://cybertirana.com/wp-content/uploads/2023/02/PYTHON-FULLSTACK-650x390.png',
    title: 'Development training',
  },
  {
    image: require('../assets/images/img_2.jpg'),
    title: 'Zero To One',
  },
  {
    image: require('../assets/images/img_3.jpg'),
    title: 'Girls who code',
  },
  {
    image: require('../assets/images/img_4.jpg'),
    title: 'Software  Solutions',
  },
  {
    image: require('../assets/images/img_5.jpg'),
    title: 'Marketing Services',
  }
];

// Carousel Component
function Carousel() {
  // Slick Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Enable center mode
    centerPadding: '50px', // Adjust padding for spacing
    responsive: [
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode for smaller screens
          centerPadding: '0', // Reset padding
        },
      },
    ],
  };

  return (
    <div style={{overflow:'hidden', marginTop:100}} className='h-screen pt-10'>
      <style>
    {`
      .slick-prev, .slick-next {
  background-color: #e03f5f; /* Set the color you want for the arrows */
  border-radius: 50%; /* Make the arrows round */
  width: 40px; /* Adjust size */
  height: 40px;
  z-index: 1; /* Ensure they stay on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev:before, .slick-next:before {
  color: white; /* Change the arrow symbol color */
  font-size: 20px; /* Adjust the arrow symbol size */
}

.slick-prev:focus, .slick-next:focus,
.slick-prev:active, .slick-next:active {
  background-color: #e03f5f; /* Keep the same color on click or focus */
  outline: none; /* Remove any outline that might be causing the issue */
}

    `}
  </style>
      <div className='w-3/4 m-auto'>
        <Slider {...settings}>
          {data.map((item, index) => (
            <div key={index} className='bg-white p-4 '> {/* Added padding for card gap */}
              <div className='flex justify-center'>
                {/* Fixed width and height for the images */}
                <img
                  src={item.image}
                  alt={item.title}
                  className='w-full h-60 object-cover block ' // Use object-cover for uniform image size
                  style={{ width: '100%', height: '450px' }} // Fixed size
                />
              </div>
              <div className='p-6 bg-[#e03f5f]'>
                <p className='font-semibold text-white text-center'>{item.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;