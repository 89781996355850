import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer-container " style={{backgroundColor:'#FFEFF1'}}>
      <div className="footer">
        <div className="footer-content">
          
          <div className="footer-row">
            <div className="footer-column">
              <div className="footer-links">
                <h3>Useful Links</h3>
                <a href="/">Home</a>
                <a href="#">Products & Services</a>
                <a href="#">Client Testimonials</a>
                <a href="#">Contact Us</a>
              </div>
            </div>
            <div className="footer-column">
              <div className="footer-links">
                <h3>Service Area</h3>
                <a href="#">Software Training</a>
                <a href="#">Software Solutions</a>
                <a href="#">Digital Marketing</a>
              </div>
            </div>
            <div className="footer-column">
              <div className="footer-contact">
                <h3>Get In Touch</h3>
                <p>
                  <i className="fa fa-map-marker" aria-hidden="true"></i> Makroniya, Madhya Pradesh 470004
                </p>
                <p>
                  <i className="fa fa-phone" aria-hidden="true"></i> +91 6263873264
                </p>
                <p>
                  <i className="fa fa-envelope"></i> admin@lanos.tech
                </p>
                <div className="footer-social">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-menu">
          <div className="f-menu">
            <a href="#">Terms of Use</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Cookies</a>
            <a href="#">Help</a>
            <a href="#">FAQs</a>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-row">
            <div className="footer-column">
              <p>
                &copy; <a href="#">Lanos Technologies</a>, All Rights Reserved.
              </p>
            </div>
            <div className="footer-column">
              <p>
                Developed & Maintained By 
                <a href="#"> Lanos Technologies</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
