import React, { useState } from 'react';

const GradientCard = () => {
  // Card data array
  const cardData = [
    {
      title: 'Full-Stack Java Development',
      description:
        'Become a pro with Java, Spring, Spring Boot, Maven, DevOps, JPA, and MySQL and more...',
      imageUrl:
      require('../assets/images/java-development.jpg'),
      buttonLabel: 'Get Started!',
    },
    {
      title: 'Build Modern Apps with MERN Stack',
      description:
        'Master MongoDB, Express, React, and Node.js to build full-stack applications from scratch.',
      imageUrl:
        'https://miro.medium.com/v2/resize:fit:1400/1*yr8XewlR1UhtO3QcyGyGAA.png',
      buttonLabel: 'Get Started!',
    },
    {
      title: 'Become a Full-Stack Python Developer',
      description:
        'Master Python with Django, build secure web apps with SMTP, and work on 80+ resume-ready projects',
      imageUrl:
        require('../assets/images/Python-development.jpg'),
      buttonLabel: 'Get Started!',
    },
  ];

  // Card component
  const Card = ({ title, description, imageUrl, buttonLabel }) => {
    const [background, setBackground] = useState(
      'linear-gradient(45deg, #ff9a9e, #fad0c4, #ffecd2)'
    );

    const handleMouseMove = (e) => {
      const card = e.currentTarget;
      const cardRect = card.getBoundingClientRect();
      const mouseX = e.clientX - cardRect.left;
      const mouseY = e.clientY - cardRect.top;
      const gradientX = (mouseX / cardRect.width) * 100;
      const gradientY = (mouseY / cardRect.height) * 100;

      setBackground(
        `radial-gradient(circle at ${gradientX}% ${gradientY}%, #ffecd2, #fcb69f, #ff9a9e)`
      );
    };

    const handleMouseLeave = () => {
      setBackground('linear-gradient(45deg, #ff9a9e, #fad0c4, #ffecd2)');
    };

    const handleButtonClick = (e) => {
      e.preventDefault();
      const button = e.currentTarget;
      button.style.transform = 'scale(0.95)';
      setTimeout(() => {
        button.style.transform = 'scale(1)';
      }, 100);
    };

    return (
      <div
        style={{
          width: '300px',
          background: 'white',
          borderRadius: '20px',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          zIndex: 1,
        }}
        className="card"
      >
        <div
          style={{
            width: '100%',
            height: '200px',
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
          className="card-image"
        ></div>
        <div
          style={{
            padding: '20px',
            background,
            transition: 'background 0.3s ease',
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          className="card-content"
        >
          <h2
            style={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#333',
              marginBottom: '10px',
            }}
          >
            {title}
          </h2>
          <p
            style={{
              fontSize: '16px',
              lineHeight: '1.6',
              color: '#666',
              fontWeight: 300,
            }}
          >
            {description}
          </p>
          <button
            style={{
              display: 'inline-block',
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#ff9a9e',
              color: 'white',
              textDecoration: 'none',
              borderRadius: '25px',
              transition: 'all 0.3s ease',
              fontWeight: 400,
              textTransform: 'uppercase',
              letterSpacing: '1px',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={handleButtonClick}
            className="card-button"
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-container">
        {cardData.map((card, index) => (
          <div key={index} className="card-container">
            <Card
              title={card.title}
              description={card.description}
              imageUrl={card.imageUrl}
              buttonLabel={card.buttonLabel}
            />
          </div>
        ))}
      </div>

      <style jsx>{`
        .main-container {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap:5%;
        }

        .card-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
        }

        @media (max-width: 500px) {
          .main-container {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default GradientCard;
