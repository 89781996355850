import React from 'react'
import { useParams } from 'react-router-dom'


const User = () => {
    const params = useParams();
  return (
    <div>I am user <span style={{color:"royalblue"}}>{params.username}</span>......... Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed asperiores explicabo sapiente sint? Alias soluta inventore nostrum mollitia error quaerat nihil, culpa est quibusdam, officiis nobis ad, repudiandae incidunt ipsa.. </div>
  )
}

export default User