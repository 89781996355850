import React from "react";

const CourseDetail = () => {
  // Array of courses
  const courses = [
    {
      title: "Full Stack Web Development",
      description: "Learn full stack development with the MERN stack: MongoDB, Express, React, and Node.js.",
      duration: "6 months",
      level: "Beginner to Advanced",
      price: "$310/26000",
      image: "https://nitsantech.com/fileadmin/ns_theme_ns2019/blog/_live/What_is_the_MERN_stack_and_how_do_I_use_it_/What_is_the_MERN_stack_and_how_do_I_use_it.jpg", 
    },
    {
      title: "Java Full Stack Development",
      description: "Master full stack development with Java, Spring Boot, and Angular.",
      duration: "6 months",
      level: "Intermediate to Advanced",
      price: "$310/26000",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi-tYsGcoQGklsok2p7_qm8lvSztxGVaYiAw&s", 
    },
    {
      title: "Python Full Stack Development",
      description: "Learn full stack development with Python, Django, and React.",
      duration: "6 months",
      level: "Beginner to Intermediate",
      price: "$310/26000",
      image: "https://www.educative.io/v2api/editorpage/5896473996165120/image/6461289576005632", 
    },
  ];

  return (
    <div className="container mx-auto my-10 p-6 max-w-5xl">
      {/* Loop through courses and display each one */}
      {courses.map((course, index) => (
        <div key={index} className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          {/* Left Side: Course Image */}
          <div className="w-full md:w-1/2">
            <img src={course.image} alt={course.title} className="object-cover w-full h-full" />
          </div>

          {/* Right Side: Course Details */}
          <div className="w-full md:w-1/2 p-6">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">{course.title}</h2>
            <p className="text-gray-600 text-base mb-4">{course.description}</p>

            <div className="text-gray-700 mb-2">
              <span className="font-semibold">Duration:</span> {course.duration}
            </div>

            <div className="text-gray-700 mb-2">
              <span className="font-semibold">Level:</span> {course.level}
            </div>

            <div className="text-gray-700 mb-4">
              <span className="font-semibold">Price:</span> {course.price}
            </div>

            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
              Enroll Now
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CourseDetail;
