import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMenu, IoClose } from 'react-icons/io5';
import logo from '../utils/Lanos_LOGO-removebg-preview (1).png';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const checkMobileView = () => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setShowMenu(false);
    }
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  const styles = {
    header: {
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'white', // Set background color to white
      zIndex: 1000,
      padding: '0.2rem 0', // Add padding to the header
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    },
    nav: {
      display: 'flex',
      alignItems: 'center', // Align items vertically
      justifyContent: 'space-between', // Space between logo and hamburger
      position: 'relative',
      height: 'var(--header-height)',
      margin: '0 auto',
      maxWidth: '1200px',
      padding: '0 1rem',
    },

    // navLogo: {
    //   color: '#e03f5f', // Set logo color
    //   transition: 'color 0.4s',
    //   fontSize: 'var(--h2-font-size)',
    //   fontFamily: 'var(--second-font)',
    //   fontWeight: 'bold', // Make the logo text bold
    // },
    navLogo: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    logoImage: {
      width: '120px', // Adjust this according to your preferred logo size
      height: 'auto', // Maintain aspect ratio
      objectFit: 'contain', // Ensure the logo is contained within the dimensions
    },
    navToggle: {
      fontSize: '1.5rem',
      color: '#e03f5f',
      cursor: 'pointer',
      display: isMobile ? 'block' : 'none',
      position: 'relative', // No need for absolute positioning
      zIndex: '100',
    },

    navMenu: {
      position: isMobile ? 'fixed' : 'static',
      top: isMobile ? 0 : 'auto',
      right: isMobile ? (showMenu ? '0' : '-100%') : 'auto',
      backgroundColor: '#fff',
      backdropFilter: isMobile ? 'blur(16px)' : 'none',
      WebkitBackdropFilter: isMobile ? 'blur(16px)' : 'none',
      width: isMobile ? '80%' : 'auto',
      height: isMobile ? '100%' : 'auto',
      padding: isMobile ? '6rem 1rem' : '0', // Reduced padding for mobile
      transition: 'right 0.4s',
      display: isMobile ? 'block' : 'flex',
      alignItems: isMobile ? 'flex-start' : 'center',
      textAlign: isMobile ? 'center' : 'center', // Center text in mobile view
    },

    navList: {
      listStyleType: 'none',
      padding: 0,
      display: isMobile ? 'block' : 'flex',
    },
    navItem: {
      margin: isMobile ? '1rem 0' : '0 1rem',
    },
    navLink: {
      color: '#e03f5f', // Set link color
      textDecoration: 'none',
      fontSize: '1.2rem',
      fontWeight: '500', // Make link text slightly bold
      transition: 'color 0.3s', // Add a transition for hover effect
    },
    navLinkActive: {
      color: '#e03f5f', // Set active link color
      fontWeight: 'bold', // Make active link text bold
    },
    // Styles for button elements
    button_signup: {
      backgroundColor: '#e03f5f', // Set button background color
      color: 'white', // Set button text color
      padding: '0.6rem 1.5rem',
      borderRadius: '1px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1rem',
      transition: 'background-color 0.3s', // Add transition for hover effect
      textDecoration: 'none', // Remove default link underline
      display: 'inline-block', // Ensure button is inline
      margin: '0 0.5rem', // Add some margin for spacing
    },
    button_login: {

      color: '#e03f5f', // Set button text color
      padding: '0.45rem 1.5rem',
      borderRadius: '1px',
      cursor: 'pointer',
      fontSize: '1rem',
      border: '2px solid #e03f5f',
      transition: 'background-color 0.3s', // Add transition for hover effect
      textDecoration: 'none', // Remove default link underline
      display: 'inline-block', // Ensure button is inline
      margin: '0 0.5rem', // Add some margin for spacing
    },
    buttonActive: {
      backgroundColor: '#d92e46', // Darker shade on active button
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', // Stack buttons vertically on mobile
      alignItems: isMobile ? 'center' : 'flex-start',
      marginTop: isMobile ? '2rem' : '0', // Add margin on mobile view
      gap: isMobile ? '1rem' : '0.5rem',  // Add gap between buttons
      lineHeight: '1.5', // Adjust line height if needed
    },


  };

  return (
    <header style={styles.header}>
      <nav style={styles.nav}>
        <Link to="/" style={styles.navLogo}>
          <img src={logo} alt="LANOS Logo" style={styles.logoImage} />
        </Link>

        <div style={styles.navToggle} onClick={toggleMenu}>
          {showMenu ? <IoClose /> : <IoMenu />}
        </div>
        <div style={styles.navMenu}>
          <ul style={styles.navList}>
            <li style={styles.navItem}>
              <Link
                to="/"
                style={styles.navLink}
                onClick={toggleMenu}
                className={window.location.pathname === '/' ? styles.navLinkActive : ''} // Add active class
              >
                Home
              </Link>
            </li>
            <li style={styles.navItem}>
              <Link
                to="/course"
                style={styles.navLink}
                onClick={toggleMenu}
                className={window.location.pathname === '/course' ? styles.navLinkActive : ''} // Add active class
              >
                Courses
              </Link>
            </li>
            <li style={styles.navItem}>
              <Link
                to="/about"
                style={styles.navLink}
                onClick={toggleMenu}
                className={window.location.pathname === '/about' ? styles.navLinkActive : ''} // Add active class
              >
                About
              </Link>
            </li>
          </ul>
          {/* <div style={styles.buttonContainer}>
            <Link to="/login" style={styles.button_login}>Log in</Link>
            <Link to="/signup" style={styles.button_signup}>Sign up</Link>
          </div> */}

        </div>
      </nav>
    </header>
  );
};

export default Navbar;
