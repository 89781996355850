import React from 'react';
import pic from '../utils/HeaderImage (1).png'

const LandingPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' , zIndex:1 , backgroundColor:'#FFEFF1' }}>
      <style>
        {`
          .container {
            padding-left: 15px;
            padding-right: 15px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 140px;
            min-height: calc(100vh - 80px);
          }
          .info {
            color: #5d5d5d;
          }
          h1 {
            font-size: 44px;
            color: #5d5d5d;
          }
          p {
            margin: 0;
            line-height: 1.6;
            font-size: 15px;
            color: #5d5d5d;
          }
          button {
            border: 0;
            border-radius: 4px;
            padding: 12px 30px;
            margin-top: 30px;
            cursor: pointer;
            color: #FFF;
            background-color: #e03f5f;
          }
          img {
            max-width: 100%;
          }
          /* Media Queries */
          @media (max-width: 767px) {
            .container {
              flex-direction: column-reverse;
              justify-content: center;
              gap: 0;
              min-height: calc(100vh - 101px);
            }
            .info {
              text-align: center;
              margin-bottom: 15px;
            }
          }
          @media (min-width: 768px) {
            .container {
              width: 750px;
            }
          }
          @media (min-width: 992px) {
            .container {
              width: 970px;
            }
          }
          @media (min-width: 1200px) {
            .container {
              width: 1170px;
            }
          }
        `}
      </style>

      <div className="content">
        <div className="container">
          <div className="info">
            <h1>Looking For Inspiration</h1>
            <p>
            Innovation fuels technology. Technology knows no limits. At the heart of this limitless journey is Lanos—shaping the future with cutting-edge education and skills.
            </p>
            <button>Enroll Now!</button>
            <button style={{marginLeft:"20px"}}>Explore Our Courses</button>
          </div>
          <div className="image">
            <img
              src={pic}
              alt="Landing page"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;