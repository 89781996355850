import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './component/Home'
import Login from './component/Login'
import Navbar from './component/Navbar'
import About from './component/About'
import User from './component/User'
import Course from './component/Course'
import Signup from './component/Signup'
import './App.css';
import Card from './component/Card'
import Carousel from './component/Carousel'
import Footer from './component/Footer'
import Contect from './component/Contect'
import Gallery from './component/gallery'


const App = () => {
  const router = createBrowserRouter([
    {
      path : "/",
      element : <><Navbar/><Home/><Card/> 
      
      
      
      <Carousel/>
      
      <Contect/> <Footer/> </>
    },
    {
      path : "/login",
      element : <><Navbar/><Login/></>
    },
    {
      path : "/about",
      element: (
        <>
          <div className="about_main">
            <div id="top">
              <Navbar/>
            </div>

            <div style={{marginTop:'6%'}} id="about_content">
              <About/>
            </div>

            <Footer/>

          </div>
        </>
      )
    },
    {
      path : "/user/:username",
      element : <><Navbar/><User/></>
    },
    {
      path : "/course",
      element : <>
      <div className="about_main">
            <div id="top">
              <Navbar/>
            </div>

            <div style={{marginTop:'4.5%'}} id="about_content">
              <Course/>
            </div>

            <Footer/>

          </div>
      </>
    },
    {
      path : "/signup",
      element : <><Navbar/><Signup/></>
    }
])

  return (
    <>
    <div className='main'>
        <RouterProvider router={router}/>
    </div>
    
    </>
  )
}

export default App