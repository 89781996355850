import React from 'react';
import './about.css'
const AboutPage = () => {
  return (
    <div className="about-page">
      <header >
        <h1>Welcome to Lanos Technologies</h1>
      </header>
      <section className="about-section">
        <h2>Our Mission</h2>
        <p>
          At Lanos Technologies, our mission is to provide high-quality training and education in the latest technologies, including MERN stack, Java, and Python. We strive to empower individuals and organizations with the skills and knowledge they need to succeed in today's fast-paced tech industry.
        </p>
      </section>
      <section className="about-section">
        <h2>Our Expertise</h2>
        <ul>
          <li><strong>MERN Stack:</strong> We specialize in providing comprehensive training in MERN stack, including MongoDB, Express, React, and Node.js.</li>
          <li><strong>Java and Python:</strong> Our team of experts also provides training in Java and Python, two of the most popular programming languages in the world.</li>
        </ul>
      </section>
      <section className="about-section">
        <h2>Why Choose Us?</h2>
        <p>
          With our expert instructors, state-of-the-art facilities, and cutting-edge curriculum, we provide a unique learning experience that sets us apart from other training providers. Our goal is to help you achieve your goals and succeed in your career.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;